import {Button, ToggleButton, ToggleButtonGroup} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Select, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Toggletip, Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split, useTheme} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CircleSmallLine,
  ThumbsDownLine,
  ThumbsUpLine,
  Twinkle2Line,
} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import DropboxOSBadgeIcon from 'assets/DropboxOSBadgeIcon.svg';
import DropboxOSBadgeDarkIcon from 'assets/DropboxOSBadgeIconDark.svg';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {growthbookCacheAtom} from 'atoms/layout';
import {snackbarAtom} from 'atoms/snackbar';
import axios from 'axios';
import {Employee, Goal, GoalUser, Update} from 'client';
import {GoalData, KeyResult} from 'client';
import {DisclaimerComponent} from 'components/ai/GoalAIAssistant';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {getStatusOptions, StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {Title} from 'components/DSYS/Title';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {useGoal, useUpdateService} from 'components/goals/hooks';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import styles from 'components/shared/StatusModal.module.css';
import {getNextQuarter} from 'components/shared/TimeAgo';
import {reportAndLogError} from 'helpers/logging';
import {isGoal as isGoalRow} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {SyntheticEvent, useEffect, useMemo, useRef, useState} from 'react';
import React from 'react';
import {Typewriter} from 'react-simple-typewriter';
import {getAIEventService, getBackendUrl} from 'utilities';
import {ContinueGoalToggle} from 'views/goals_v2/ContinueGoal';
import {ContinueGoalButton} from 'views/goals_v2/ContinueGoalButton';
import {GoalHeaderDetails} from 'views/goals_v2/GoalHeaderDetails';
import {SubtleKeyResultIcon, SubtleObjectiveIcon} from 'views/goals_v2/icons';
import {TeamInfo} from 'views/goals_v2/types';
import {hasOpenStatusKeyResult, isClosedStatus} from 'views/goals_v2/utils';
import {getRowData} from 'views/goals_v2/utils';

import {DrawerRow} from './Drawers/GoalDetailsDrawerBody';

type StateType = {open: boolean; action: string};

const inferStatusUpdatePrompt = (newStatus: string) => {
  switch (newStatus) {
    case 'on_track':
      return {promptStr: 'status_update_helper_on_track', required: false};
    case 'at_risk':
      return {promptStr: 'status_update_helper_kr_at_risk_or_off_track', required: true};
    case 'off_track':
      return {promptStr: 'status_update_helper_kr_at_risk_or_off_track', required: true};
    case 'complete':
      return {promptStr: 'status_update_helper_complete', required: false};
    case 'cancelled':
      return {promptStr: 'status_update_helper_cancelled', required: true};
    case 'missed':
      return {promptStr: 'status_update_helper_missed', required: true};
    default:
      return {promptStr: 'status_update_helper', required: false};
  }
};

const StatusModalHeader = ({
  goal,
  keyResult,
  delegateChip,
}: {
  goal: GoalData;
  keyResult?: KeyResult;
  delegateChip?: React.ReactNode;
}) => {
  const {delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const pulseUser = useAtomValue(pulseUserAtom);

  const row = keyResult ? keyResult : goal;
  const isGoal = isGoalRow(row);
  const goalOwner = goal.users![0];
  const {numKeyResults} = getRowData(row, goalOwner, pulseUser?.email, delegatedBy);
  const currentRowTimeframe = row.timeframe ?? goal.timeframe;
  const subGoals = keyResult
    ? goal.key_results_aligned_goals?.filter(
        (alignedGoal) => alignedGoal.key_result_parent_id === keyResult.id
      )
    : goal.children;
  const numSubGoals = subGoals?.length ?? 0;

  const title = keyResult ? t('key_result_alone') : t('objective');
  const icon = keyResult ? SubtleKeyResultIcon : SubtleObjectiveIcon;

  return (
    <Modal.Header
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <Box as="div" alignItems="center" display="flex" style={{gap: '4px', marginLeft: '-5px'}}>
        <Box as={UIIcon} src={icon} color="Text Subtle" />
        <Box as={Eyebrow} color="Text Subtle" size="xsmall">
          {title}
        </Box>
      </Box>
      <Box as="div" display="flex" justifyContent="space-between" marginTop="12">
        <Box>
          <Title size={18}>
            <Truncate lines={2} style={{hyphens: 'auto'}}>
              {row.title}
            </Truncate>
          </Title>
          <Box marginTop="4">
            <GoalHeaderDetails
              isGoalRow={isGoal}
              goal={goal}
              currentRowTimeframe={currentRowTimeframe}
              numKeyResults={numKeyResults}
              numSubGoals={numSubGoals}
            />
          </Box>
        </Box>
        {delegateChip}
      </Box>
    </Modal.Header>
  );
};

export const StatusModal = ({
  goalId,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  user,
  keyResultId,
  state,
  setState,
  setIsStatusModalOpen,
  previousUpdate,
  teamInfo,
  parentGoalUser,
  ownerEmployeeLdap,
  onSuccess,
  onContinueGoalClick,

  updateCount,
  setUpdateCount,
  suggestText,
  setSuggestText,
}: {
  title: string;
  keyResultId?: number;
  goalId: number;
  timeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
  user: GoalUser;
  state: StateType;
  setState: React.Dispatch<React.SetStateAction<StateType>>;
  setIsStatusModalOpen?: (isOpen: boolean) => void;
  previousUpdate?: Update;
  teamInfo?: TeamInfo;
  parentGoalUser?: GoalUser;
  ownerEmployeeLdap?: string;
  onSuccess?: () => void;
  onContinueGoalClick?: (selectedStatus: string) => void;

  updateCount?: number;
  setUpdateCount?: (updateCount: number) => void;
  suggestText?: string;
  setSuggestText?: (suggestText: string) => void;
}) => {
  const {mode} = useTheme();
  const [saveState, setSaveState] = useState('idle');
  const [updateAs, setUpdateAs] = useState<Employee>();

  const [selectedStatus, setSelectedStatus] = useState(
    previousUpdate ? previousUpdate.status : 'no_status'
  );

  const {isKRUpdateAIEnabled} = useAtomValue(growthbookCacheAtom);

  const {delegatedBy, employee} = useAtomValue(loggedInEmployeeAtom);
  const ownerLdap = user.email.split('@')[0];
  const isDelegated = delegatedBy?.some(({email}) => email === user.email);

  const editorState = useRef<EditorState>();
  const [currentMetric, setCurrentMetric] = useState(previousUpdate?.current_metric ?? '');
  const [targetMetric, setTargetMetric] = useState(previousUpdate?.target_metric ?? '');
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const canSave = selectedStatus !== 'no_status' && saveState === 'idle';

  const isGoal = goalId && !keyResultId;

  const goal = useGoal({goalId});
  const keyResult = keyResultId ? goal?.key_results.find(({id}) => id === keyResultId) : undefined;
  const contributorLdaps = useMemo(
    () => keyResult?.contributors?.map(({ldap}) => ldap) ?? [],
    [keyResult?.contributors]
  );

  const ownerLdaps = ownerEmployeeLdap ? new Set([ownerEmployeeLdap]) : new Set<string>();
  if (ownerLdap && ownerLdap !== ownerEmployeeLdap) {
    ownerLdaps.add(ownerLdap);
  }
  if (keyResult) {
    keyResult.contributors.map(({ldap}) => ldap).forEach((ldap) => ownerLdaps.add(ldap));
  }

  const {addUpdate} = useUpdateService({
    timeframe: timeframe,
    teamInfo: teamInfo,
    parentGoalUserLdap: parentGoalUser?.email.split('@')[0],
    ownerLdaps: Array.from(ownerLdaps),
    onSettledCallback: onSuccess,
  });

  const enforceEnableContinueGoalToggle = state.action === 'enforce-enable-continue-goal-toggle';
  const [isContinueGoalToggled, setIsContinueGoalToggled] = useState(
    enforceEnableContinueGoalToggle
  );
  const showContinueGoalToggle =
    onContinueGoalClick &&
    (keyResult ? true : !hasOpenStatusKeyResult(goal, user)) &&
    (enforceEnableContinueGoalToggle || isClosedStatus(selectedStatus, false));
  const showContinueGoalSplitButton = showContinueGoalToggle && isContinueGoalToggled;
  let options = getStatusOptions(mode);
  if (enforceEnableContinueGoalToggle) {
    // Only show 'closed' status options when Continue Goal toggle is enabled via action menu
    options = options.filter((statusOption) => statusOption.label === 'closed');
  }

  const [statusUpdatePrompt, setStatusUpdatePrompt] = useState<string>('status_update_helper');
  const [statusUpdateRequired, setStatusUpdateRequired] = useState(false);
  const [hasStatusUpdate, setHasStatusUpdate] = useState(
    previousUpdate && previousUpdate.comment !== ''
  );
  const disableSubmit = !canSave || (statusUpdateRequired && !hasStatusUpdate);

  useEffect(() => {
    if (isDelegated) {
      setUpdateAs(delegatedBy?.find(({email}) => email === user.email));
    }
  }, [delegatedBy, isDelegated, user.email]);

  const closeModal = () => {
    setState({open: false, action: ''});
    setIsStatusModalOpen?.(false);
    setNextTimeframe(getNextQuarter(timeframe));
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const confirm = async (hideSuccessSnackbar?: boolean) => {
    if (canSave) {
      try {
        setSaveState('pending');
        await addUpdate({
          userId: user.user_id,
          employeeId: updateAs ? updateAs.user_id : undefined,
          isDelegated: (updateAs && updateAs.email !== employee.email) ?? false,
          ldap: user.email.split('@')[0],
          data: {
            goal_id: goalId,
            key_result_id: keyResultId,
            status: selectedStatus,
            comment: isGoal ? '' : editorState.current?.toString() ?? '',
            current_metric: currentMetric,
            target_metric: targetMetric,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        });
        setSaveState('idle');
        if (!hideSuccessSnackbar) {
          setSnackbarMessage({text: t('status_updated')});
        }
      } catch (error) {
        setSaveState('error');
        reportAndLogError(error, 'Could not update status');
        setSnackbarMessage({text: t('couldnt_update_status')});
      }
    }
    setState({open: false, action: ''});
    setIsStatusModalOpen?.(false);
  };

  const handleStatusChange = (val: string, event: SyntheticEvent<Element, Event>) => {
    setSelectedStatus(val);
    if (!isGoal) {
      const {promptStr, required} = inferStatusUpdatePrompt(val);
      setStatusUpdatePrompt(promptStr);
      setStatusUpdateRequired(required);
    }
    if (!enforceEnableContinueGoalToggle) {
      setIsContinueGoalToggled(false);
    }
    event.stopPropagation();
  };

  let updates: Update[] | undefined;
  let subGoals: GoalData[] | undefined;

  if (keyResult) {
    const goalOwner = goal.users![0];
    const {latestUpdate, previousUpdates} = getRowData(keyResult, goalOwner);
    // filter out the updates that are undefined
    updates = [...previousUpdates, latestUpdate].filter((update) => update);

    subGoals = goal.key_results_aligned_goals?.filter(
      (alignedGoal) => alignedGoal.key_result_parent_id === keyResult.id
    );
  }

  const shouldShowSummary = useMemo(() => {
    if ((!subGoals || subGoals.length === 0) && updates && updates.length <= 2) {
      return false;
    }
    return true;
  }, [subGoals, updates]);

  useEffect(() => {
    setSelectedStatus(previousUpdate ? previousUpdate.status : 'no_status');
  }, [previousUpdate, setSelectedStatus]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'k')) {
        closeModal();
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        if (canSave) {
          confirm();
          e.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.open]);

  return (
    <Modal
      width={keyResult && isKRUpdateAIEnabled ? 968 : 'standard'}
      open={state.open}
      onRequestClose={(e: React.SyntheticEvent) => {
        closeModal();
        e.stopPropagation();
      }}
      isCentered
      withCloseButton="Close"
      onAfterOpen={(obj?: {overlayEl: Element; contentEl: HTMLDivElement} | undefined) => {
        if (obj) {
          const {overlayEl} = obj;
          overlayEl.addEventListener('click', function (event) {
            if (event.target === overlayEl) {
              closeModal();
              event.stopPropagation();
            }
          });
        }
      }}
    >
      <StatusModalHeader
        goal={goal}
        keyResult={keyResult}
        delegateChip={
          <Split alignX="right" style={{marginLeft: '8px'}}>
            {!isGoal && ownerLdap !== employee.ldap && contributorLdaps.includes(employee.ldap) ? (
              <Split.Item>
                {delegatedBy && (
                  <DelegateSelectMenu
                    delegateId={updateAs?.user_id}
                    setDelegateId={(id) =>
                      setUpdateAs(delegatedBy.find(({user_id}) => user_id === id))
                    }
                    filterLdaps={delegatedBy
                      .map(({ldap}) => ldap)
                      .filter((ldap) => !contributorLdaps.includes(ldap) && ldap !== ownerLdap)}
                  />
                )}
              </Split.Item>
            ) : (
              updateAs?.email === user.email && (
                <Split.Item>
                  {delegatedBy && (
                    <DelegateSelectMenu
                      delegateId={updateAs?.user_id}
                      showDisabledDelegateChip={true}
                    />
                  )}
                </Split.Item>
              )
            )}
          </Split>
        }
      />
      <Box as="div" display="flex">
        <Box style={{width: keyResult && isKRUpdateAIEnabled ? '572px' : '600px'}}>
          <Modal.Body
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
            }}
            style={{
              height:
                keyResult && isKRUpdateAIEnabled
                  ? Math.min(580, windowHeight - 320)
                  : keyResult
                  ? Math.min(600, windowHeight - 320)
                  : 450,
              overflow: 'auto',
            }}
            className={styles.statusSelectOptionOverride}
          >
            <FormRow>
              <FormLabel
                htmlFor="status"
                subtext={isGoal ? '' : <Toggletip title={t('status_tooltip')} />}
              >
                {t('status')}
              </FormLabel>
              <Select
                id="status"
                defaultValue={previousUpdate ? previousUpdate.status : 'no_status'}
                onChange={(val, e) => handleStatusChange(val, e)}
                value={selectedStatus}
                onClick={(e: React.SyntheticEvent) => {
                  e.stopPropagation();
                }}
              >
                {options
                  .filter((statusOption) => statusOption.label !== 'draft')
                  .map((statusOption) => (
                    <Select.OptGroup key={statusOption.label} withLabel={t(statusOption.label)}>
                      {statusOption.statusOptions.map((status) => (
                        <Select.Option
                          className={styles.statusOptionOverride}
                          key={status.value}
                          value={status.value}
                          withAccessory={
                            <UIIcon
                              src={status.icon}
                              color={status.color}
                              size="medium"
                              style={{height: '24px', width: '24px'}}
                            />
                          }
                        >
                          {t(status.value).toString()}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                <Select.Option
                  value={previousUpdate ? previousUpdate.status : 'no_status'}
                  withAccessory={<StatusButtonIcon status={previousUpdate?.status} />}
                  hidden
                >
                  {previousUpdate ? t(previousUpdate.status).toString() : t('no_status').toString()}
                </Select.Option>
              </Select>
              {isGoal && (
                <Split
                  gap="8"
                  alignY="top"
                  paddingTop="8"
                  paddingLeft="12"
                  style={{height: isGoal ? 250 : 24}}
                >
                  <Split.Item>
                    <Box as={UIIcon} src={Twinkle2Line} color="Text Subtle" />
                  </Split.Item>
                  <Split.Item>
                    <Text color="faint" size="small">
                      {t('automatic_objective_status_change')}
                    </Text>
                  </Split.Item>
                </Split>
              )}
            </FormRow>

            {!isGoal && (
              <>
                <FormRow>
                  <FormLabel
                    htmlFor="statusUpdate"
                    // subtext={<Toggletip title={t(statusUpdatePrompt)} />}
                  >
                    {t('status_update')}
                  </FormLabel>
                  {shouldShowSummary && (
                    <Box paddingBottom="8">
                      <Text color="faint">{t(statusUpdatePrompt)}</Text>
                    </Box>
                  )}
                  <RichTextArea
                    editable
                    value={previousUpdate?.comment}
                    selectAll
                    theme="editor"
                    source="goal-status"
                    minHeight={190}
                    placeholder={t('status_update_placeholder').toString()}
                    onChange={(editor) => {
                      editorState.current = editor;
                      if (editor.toString() == '') {
                        setHasStatusUpdate(false);
                      } else {
                        setHasStatusUpdate(true);
                      }
                    }}
                  />
                </FormRow>
                <FormRow>
                  <FormLabel htmlFor="metric" subtext={<Toggletip title={t('metric_tooltip')} />}>
                    {t('metric')}
                  </FormLabel>
                  <Split gap="8">
                    <Split.Item width="fill">
                      <TextInput
                        id="metric"
                        placeholder={t('current_metric').toString()}
                        size="large"
                        value={currentMetric}
                        onChange={(e) => setCurrentMetric(e.currentTarget.value)}
                        autoComplete="off"
                      />
                    </Split.Item>
                    <Split.Item width="fill">
                      <TextInput
                        placeholder={t('target_metric').toString()}
                        size="large"
                        value={targetMetric}
                        onChange={(e) => setTargetMetric(e.currentTarget.value)}
                        autoComplete="off"
                      />
                    </Split.Item>
                  </Split>
                </FormRow>
              </>
            )}
          </Modal.Body>
          <Modal.Footer
            preferComposition
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
            }}
          >
            <Split gap="8" alignX="right">
              {showContinueGoalToggle && (
                <Split.Item width="fill">
                  <ContinueGoalToggle
                    isContinueGoalToggled={isContinueGoalToggled}
                    setIsContinueGoalToggled={(isToggled: boolean) => {
                      if (isToggled) {
                        analyticsLogger().logEvent('CONTINUE_GOAL_TOGGLED', {
                          type: keyResultId ? 'kr' : 'objective',
                          status: selectedStatus,
                        });
                      }
                      setIsContinueGoalToggled(isToggled);
                    }}
                    enforceEnableContinueGoalToggle={enforceEnableContinueGoalToggle}
                  />
                </Split.Item>
              )}
              <Split.Item>
                <Button
                  variant="opacity"
                  onClick={(e: React.SyntheticEvent) => {
                    closeModal();
                    e.stopPropagation();
                  }}
                >
                  {t('cancel')}
                </Button>
              </Split.Item>
              <Split.Item>
                {showContinueGoalSplitButton ? (
                  <ContinueGoalButton
                    currentTimeframe={timeframe}
                    nextTimeframe={nextTimeframe}
                    setNextTimeframe={setNextTimeframe}
                    handleContinueGoal={() => {
                      if (isGoal && hasOpenStatusKeyResult(goal, user)) {
                        setSnackbarMessage({text: t('continue_goal_try_again_message')});
                      } else {
                        confirm(true);
                        onContinueGoalClick(selectedStatus);
                        closeModal();
                      }
                    }}
                    disabled={disableSubmit}
                  />
                ) : (
                  <Button
                    variant="primary"
                    onClick={(e: React.SyntheticEvent) => {
                      confirm();
                      e.stopPropagation();
                    }}
                    disabled={disableSubmit}
                    isLoading={saveState === 'pending'}
                  >
                    {t('update')}
                  </Button>
                )}
              </Split.Item>
            </Split>
          </Modal.Footer>
        </Box>
        {keyResult && isKRUpdateAIEnabled && (
          <Box>
            <Modal.Body
              style={{
                borderLeft: '1px solid var(--dig-color__border__subtle)',
                height: Math.min(windowHeight - 244, 660),
                overflow: 'auto',
              }}
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
              }}
            >
              <StatusAIDrawer
                goal={goal}
                keyResult={keyResult}
                setUpdateCount={setUpdateCount}
                updateCount={updateCount}
                suggestText={suggestText}
                setSuggestText={setSuggestText}
                statusUpdatePrompt={statusUpdatePrompt}
              />
            </Modal.Body>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const StatusAIDrawer = ({
  goal,
  keyResult,
  updateCount,
  setUpdateCount,
  suggestText,
  setSuggestText,
  statusUpdatePrompt,
}: {
  goal: GoalData;
  keyResult: KeyResult;
  updateCount?: number;
  setUpdateCount?: (updateCount: number) => void;
  suggestText?: string;
  setSuggestText?: (suggestText: string) => void;
  statusUpdatePrompt: string;
}) => {
  const {mode} = useTheme();
  // const [word, setWord] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [disableStream, setDisableStream] = useState(false);
  const goalOwner = goal.users![0];

  const {latestUpdate, previousUpdates} = getRowData(keyResult, goalOwner);

  // filter out the updates that are undefined
  const updates = [...previousUpdates, latestUpdate].filter((update) => update);

  const subGoals = useMemo(
    () =>
      goal.key_results_aligned_goals?.filter(
        (alignedGoal) => alignedGoal.key_result_parent_id === keyResult.id
      ),
    [goal.key_results_aligned_goals, keyResult.id]
  );

  const shouldShowSummary = useMemo(() => {
    if ((!subGoals || subGoals.length === 0) && updates && updates.length <= 2) {
      return false;
    }
    return true;
  }, [subGoals, updates]);

  const hasFetchedSummary = useRef(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!shouldShowSummary || loading || hasFetchedSummary.current) {
      return;
    }
    if (updateCount === updates.length) {
      setDisableStream(() => true);
      return;
    }
    setLoading(() => true);
    setDisableStream(() => false);
    hasFetchedSummary.current = true;
    axios
      .post(
        `${getBackendUrl()}/api/v1/key_result/update_summary`,
        JSON.stringify({
          goalId: goal.id,
          keyResultId: keyResult.id,
        }),
        {
          cancelToken: source.token,
          withCredentials: true,
        }
      )
      .then((response) => {
        const responseJson = JSON.parse(response.data);
        setSuggestText?.(responseJson.summary);
        setLoading(() => false);
        setIsTyping(() => true);
        setUpdateCount?.(updates.length);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Only log error if not cancelled
          console.error(error);
          setLoading(() => false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal.id, keyResult.id]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Box as="div" style={{width: '348px', paddingTop: '18px'}}>
          <Box marginBottom="12" style={{height: '28px', width: '28px'}}>
            <img
              src={mode === 'dark' ? DropboxOSBadgeDarkIcon : DropboxOSBadgeIcon}
              alt="Dropbox OS Badge"
              width="28"
              height="28"
            />
          </Box>
          <Box as={Text} variant="label" size="large" display="block" isBold>
            {shouldShowSummary
              ? t('here_some_more_info_about_this_goal')
              : t('let_write_a_great_status_update')}
          </Box>
          <Box as={Text} variant="paragraph" color="Text Subtle" display="block" marginTop="4">
            {shouldShowSummary
              ? t('additional_context_about_this_key_result')
              : t('here_some_prompts_to_help_you')}
          </Box>
        </Box>
        {!shouldShowSummary && <EmptyPromptSection statusUpdatePrompt={statusUpdatePrompt} />}
        {shouldShowSummary && (
          <>
            <Box as="div" display="flex" flexDirection="column" justifyContent="space-between">
              <Box>
                <SummarySection
                  word={suggestText ?? ''}
                  loading={loading}
                  setIsTyping={setIsTyping}
                  disableStream={disableStream}
                  keyResultId={keyResult.id}
                />
                <SourceSection goal={goal} keyResult={keyResult} isLoading={loading || isTyping} />
              </Box>
            </Box>
          </>
        )}
      </Box>
      {shouldShowSummary && (
        <Box style={{width: '348px', marginBottom: '0px', marginTop: '18px'}}>
          <DisclaimerComponent marginBottom="0px" />
        </Box>
      )}
    </Box>
  );
};

const EmptyPromptSection = ({statusUpdatePrompt}: {statusUpdatePrompt: string}) => {
  const texts =
    statusUpdatePrompt === 'status_update_helper'
      ? [
          t('what_have_you_gotten_done_on_this_kr_so_far'),
          t('have_you_run_into_any_issues'),
          t('what_the_next_thing_you_are_going_to_tackle'),
        ]
      : (t(statusUpdatePrompt).split('?') as string[]);
  const filterTexts = texts
    .filter((text) => text.trim() !== '')
    .map((text) => {
      let result = text.trim();
      if (!result.endsWith('?')) {
        result = result + '?';
      }
      return result;
    });

  return (
    <Box as="div" style={{width: '348px', paddingTop: '18px'}}>
      {filterTexts.map((text, index) => (
        <Box
          as="div"
          key={index}
          display="flex"
          alignItems="flex-start"
          style={{gap: '2px', padding: '4px 0px'}}
        >
          <Box as="div" style={{width: '24px', height: '24px'}}>
            <Box as={UIIcon} src={CircleSmallLine} color="Border Subtle" />
          </Box>
          <Box as={Text} variant="label" paddingTop="2">
            {text}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const SummarySection = ({
  word,
  loading,
  setIsTyping,
  disableStream,
  keyResultId,
}: {
  word: string;
  loading: boolean;
  setIsTyping: (typing: boolean) => void;
  disableStream: boolean;
  keyResultId: number;
}) => {
  const [selected, setSelected] = useState<string | null>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleSelected = (value: string | null) => {
    setSelected(value);
    if (value === '0') {
      handleThumbUpClick();
    } else if (value === '1') {
      handleThumbDownClick();
    }
  };

  const handleThumbUpClick = () => {
    const aiEvent = {
      new_content: word,
      old_content: '',
      created_at: new Date().toISOString(),
      type: 'kr_update_thumb_up',
      associated_id: keyResultId,
    };
    analyticsLogger().logEvent('KEY_RESULT_UPDATE_AI_THUMB_UP');
    getAIEventService().createAiEventApiV1AiEventPost(aiEvent);
  };

  const handleThumbDownClick = () => {
    const aiEvent = {
      new_content: word,
      old_content: '',
      created_at: new Date().toISOString(),
      type: 'kr_update_thumb_down',
      associated_id: keyResultId,
    };
    analyticsLogger().logEvent('KEY_RESULT_UPDATE_AI_THUMB_DOWN');
    getAIEventService().createAiEventApiV1AiEventPost(aiEvent);
  };

  const copyToClickboard = () => {
    navigator.clipboard.writeText(word);
  };

  // disale toolip after 2 seconds
  useEffect(() => {
    if (showTooltip) {
      const timeout = setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [showTooltip]);

  if (loading) {
    return <Skeleton.Box height={100} style={{width: '348px', marginTop: '18px'}} />;
  }

  return (
    <Box as="div" style={{width: '348px', paddingTop: '18px'}}>
      <Box
        as="div"
        padding="16"
        borderRadius="Medium"
        borderStyle="Solid"
        borderColor="Border Subtle"
        ref={triggerRef}
        onClick={(e) => {
          setShowTooltip((prev) => !prev);
          e.stopPropagation();
          copyToClickboard();
        }}
      >
        <Box
          paddingLeft="16"
          borderLeft="Solid"
          borderColor="Primary Surface - State 1"
          paddingTop="4"
          paddingBottom="4"
          borderWidth="2"
        >
          <Box as={Text} variant="paragraph" display="block">
            {disableStream ? (
              word
            ) : (
              <Typewriter words={[word]} typeSpeed={3} onLoopDone={() => setIsTyping(false)} />
            )}
          </Box>
        </Box>
      </Box>
      <Tooltip.Control variant="basic" open={showTooltip} placement="top" triggerRef={triggerRef}>
        <Text variant="label" size="small">
          {t('copied_to_clipboard')}
        </Text>
      </Tooltip.Control>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'flex-end',
          marginTop: '8px',
          paddingTop: '4px',
          paddingBottom: '4px',
        }}
      >
        <ToggleButtonGroup
          onSelection={({value}) => handleSelected(value)}
          variant="outline"
          size="small"
          shape="circular"
          selected={selected}
          aria-label="Thumb up or down"
          exclusive
        >
          {(selected == null || selected === '0') && (
            <ToggleButton
              variant="outline"
              size="small"
              shape="circular"
              onSelection={handleThumbUpClick}
              iconSrc={ThumbsUpLine}
              aria-label={''}
              value={'0'}
              isSelected={selected === '0'}
            ></ToggleButton>
          )}
          {(selected == null || selected === '1') && (
            <ToggleButton
              variant="outline"
              size="small"
              shape="circular"
              onSelection={handleThumbDownClick}
              iconSrc={ThumbsDownLine}
              aria-label={''}
              value={'1'}
              isSelected={selected === '1'}
            ></ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

const SourceSection = ({
  goal,
  keyResult,
  isLoading,
}: {
  goal: GoalData;
  keyResult: KeyResult;
  isLoading: boolean;
}) => {
  const goalOwner = goal.users![0];
  const {latestUpdate, previousUpdates} = getRowData(keyResult, goalOwner);

  // filter out the updates that are undefined
  const updates = [...previousUpdates, latestUpdate].filter((update) => update);

  const subGoals = goal.key_results_aligned_goals?.filter(
    (alignedGoal) => alignedGoal.key_result_parent_id === keyResult.id
  );

  const [selectedTab, setSelectedTab] = useState(
    updates && updates.length > 0 ? 'updates' : 'subGoals'
  );

  if ((!subGoals || subGoals.length === 0) && updates && updates.length <= 2) {
    return null;
  }

  const calculateMinHeight = () => {
    return Math.min(updates.length * 150, 560) + 'px';
  };

  return (
    <Box as="div" style={{width: '348px'}} className={styles.tabGroup}>
      <Eyebrow size="xsmall">Sources</Eyebrow>
      {isLoading ? (
        <Box display="flex" flexDirection="column" style={{gap: '8px', marginTop: '18px'}}>
          <Skeleton.Box height={80} style={{width: '348px'}} />
          <Skeleton.Box height={80} style={{width: '348px'}} />
          <Skeleton.Box height={80} style={{width: '348px'}} />
        </Box>
      ) : (
        <Tabs selectedTab={selectedTab} onSelection={setSelectedTab}>
          <Tabs.Group
            variant="responsive"
            hasDivider={true}
            overflowTriggerProps={{
              renderLabel: (hiddens) =>
                `More (${hiddens.length}) See more tabs in this overflow menu`,
            }}
          >
            {updates && updates.length > 0 && <Tabs.Tab id="updates">{t('updates')}</Tabs.Tab>}
            {subGoals && subGoals.length > 0 && <Tabs.Tab id="subGoals">{t('sub-goals')}</Tabs.Tab>}
          </Tabs.Group>
          {updates && updates.length > 0 && (
            <Tabs.Panel tabId="updates" tabIndex={0}>
              <PreviousUpdateSection goal={goal} updates={updates} />
            </Tabs.Panel>
          )}
          {subGoals && (
            <Tabs.Panel tabId="subGoals" tabIndex={1} style={{minHeight: calculateMinHeight()}}>
              <SubGoalsSection subGoals={subGoals} />
            </Tabs.Panel>
          )}
        </Tabs>
      )}
    </Box>
  );
};

const PreviousUpdateSection = ({updates, goal}: {updates: Update[]; goal: GoalData}) => {
  const goalOwner = goal.users![0];
  const sortedUpdates = updates.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  return (
    <Box marginTop="16">
      {sortedUpdates.map((update) => {
        const updateLdap = update?.employee?.ldap || goalOwner.email.split('@')[0];
        if (!update) {
          return null;
        }
        return (
          <UpdatePost
            key={update.id}
            ldap={updateLdap}
            update={update}
            isAutomaticObjectiveStatusChange={false}
          >
            <RichTextArea value={update.comment} source="goal-status" />
          </UpdatePost>
        );
      })}
    </Box>
  );
};

const SubGoalsSection = ({subGoals}: {subGoals: Goal[]}) => {
  return (
    <Box marginTop="16">
      {subGoals.map((subGoal) => (
        <DrawerRow
          source="update"
          key={`goal-update__sub-goal__${subGoal.id}`}
          goal={subGoal}
          setIsGoalDetailsDrawerOpen={() => {}}
          onClickHandler={(e: React.SyntheticEvent) => {
            // open a new tab with the goal details without closing the modal and influencing the current state
            window.open(`/goals/${subGoal.id}`, '_blank');
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      ))}
    </Box>
  );
};
