import {PulseUser} from 'atoms/auth';
import {Employee, Goal, GoalData, GoalRead, GoalUser, KeyResult, Team, Update} from 'client';
import {ROUTE_PATHS, STEAM_TEAM_NAME, STEAM_TEAM_SLUG} from 'constant';
import {isDev, isProd} from 'helpers/environment';
import {isGoalPrivate, isGoalType} from 'helpers/utils';
import {Location} from 'react-router-dom';
import {emailToLdap} from 'utilities';
import {DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';

import {DrawerGoalData, GoalsHeaderData, GoalsTableFilter, SelectedKey} from './types';

export const MAX_NUM_GOALS_TO_SHOW = 100;

export const getEmptyFilter = (): GoalsTableFilter => ({
  teams: [],
  status: [],
  updated: [],
  search: null,
  reports: '0',
  people: [],
});

export const getInitialJoinFilter = (people: string[]): GoalsTableFilter => ({
  teams: [],
  status: [],
  updated: [],
  search: '',
  reports: '0',
  people: people,
});

export const initialQuickFilterSelected = 'myGoals';

export const groupGoalsByLdap = (
  goals: Goal[],
  ldaps: string[],
  shouldGroupGoalsByEmployee: boolean,
  teamMemberLdaps: string[] = [],
  showEmployeesWithNoGoals?: boolean
): Record<string, Goal[]> => {
  const groupedGoals = goals.reduce(
    (acc, goal) => {
      const ownerLdap = emailToLdap(goal.users?.[0].email);
      const goalContributorLdaps = goal.key_results.flatMap((keyResult) =>
        keyResult.contributors.map(({ldap}) => ldap)
      );

      if (ownerLdap) {
        const isOwner = ldaps.some((r) => r === ownerLdap);
        const contributorLdaps = goalContributorLdaps.filter((r) => ldaps.includes(r));

        if (isOwner) {
          if (!acc[ownerLdap]) {
            // eslint-disable-next-line no-param-reassign
            acc[ownerLdap] = [];
          }
          if (!acc[ownerLdap].some(({id}) => id === goal.id)) {
            acc[ownerLdap].push(goal);
          }
        }

        contributorLdaps.forEach((contributorLdap) => {
          if (shouldGroupGoalsByEmployee && teamMemberLdaps.includes(ownerLdap)) {
            return; // for Team goals table, do not include contributing goals of team members
          }
          if (!acc[contributorLdap]) {
            // eslint-disable-next-line no-param-reassign
            acc[contributorLdap] = [];
          }
          // Check if the goal ID is already added for the contributor
          if (!acc[contributorLdap].some(({id}) => id === goal.id)) {
            acc[contributorLdap].push(goal);
          }
        });
      }
      return acc;
    },
    {} as Record<string, Goal[]>
  );

  if (shouldGroupGoalsByEmployee && showEmployeesWithNoGoals) {
    // For Team goals table, ensure ldaps are in table even if they don't have goals
    ldaps.forEach((ldap) => {
      if (!groupedGoals[ldap]) {
        groupedGoals[ldap] = [];
      }
    });
  }

  const sortedGoals: Record<string, Goal[]> = {};
  Object.entries(groupedGoals).forEach(([ldap, ldapGoals]) => {
    // First, sort all goals by creation date (newest last)
    const sortedByDate = ldapGoals.sort((a, b) => {
      const dateA = a.created_at ? new Date(a.created_at).getTime() : -Infinity;
      const dateB = b.created_at ? new Date(b.created_at).getTime() : -Infinity;
      return dateA - dateB;
    });

    // Separate owner goals from contributing goals
    const ownerGoals: Goal[] = [];
    const contributingGoals: Goal[] = [];

    sortedByDate.forEach((goal) => {
      const goalOwner = emailToLdap(goal.users![0].email);
      if (goalOwner === ldap) {
        ownerGoals.push(goal);
      } else {
        contributingGoals.push(goal);
      }
    });

    // Group contributing goals by owner
    contributingGoals.sort((a, b) => {
      const aUserId = a.users![0].user_id;
      const bUserId = b.users![0].user_id;
      return aUserId.localeCompare(bUserId);
    });

    // Remove aligned goals from contributing goals if they're also a top level goal to avoid displaying duplicate goals
    const topLevelGoalIds = [...ownerGoals, ...contributingGoals].map((goal) => goal.id);
    const filteredContributingGoals = contributingGoals.map((goal) => {
      return {
        ...goal,
        children: goal.children?.filter((alignedGoal) => !topLevelGoalIds.includes(alignedGoal.id)),
      };
    });

    // Order owner goals first, then contributing goals
    sortedGoals[ldap] = [...ownerGoals, ...filteredContributingGoals];
  });

  return sortedGoals;
};

export const dummyGoalId = isDev ? 27439 : isProd ? 11395 : 145;
export const dummyKRId = isDev ? 54686 : isProd ? 27597 : 203;

export const filterTable = (filter: GoalsTableFilter) => (data: Goal) => {
  let show = true;

  if (filter.updated.length) {
    const allUpdates = data.updates;
    data.key_results.forEach((keyResult) => {
      allUpdates.push(...keyResult.updates);
    });
    const updates = allUpdates.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    let lastUpdated = undefined;
    if (updates.length > 0) {
      lastUpdated = new Date(updates[0].created_at);
    }

    const now = new Date();
    if (
      filter.updated.includes('filter_less_than_45_days') &&
      filter.updated.includes('filter_more_than_45_days')
    ) {
      show = true;
    } else if (filter.updated.includes('filter_less_than_45_days')) {
      if (lastUpdated !== undefined) {
        const days = Math.floor((now.getTime() - lastUpdated.getTime()) / (1000 * 60 * 60 * 24));
        show = days < 45;
      } else {
        show = false;
      }
    } else if (filter.updated.includes('filter_more_than_45_days')) {
      if (lastUpdated !== undefined) {
        const days = Math.floor((now.getTime() - lastUpdated.getTime()) / (1000 * 60 * 60 * 24));
        show = days > 45;
      } else {
        show = true;
      }
    }
  }

  if (filter.status.length) {
    if (
      data.updates.length > 0 &&
      !filter.status.includes(data.updates[data.updates.length - 1].status)
    ) {
      show = false;
    } else if (
      data.updates.length === 0 &&
      !filter.status.includes('no_status') &&
      !data.is_draft
    ) {
      show = false;
    } else if (data.updates.length === 0 && !filter.status.includes('draft') && data.is_draft) {
      show = false;
    }
  }

  if (filter.search) {
    show = show && data.title.toLowerCase().includes(filter.search.toLowerCase());
  }

  return show;
};

export const shouldShowMultiGoalsTable = (filter: GoalsTableFilter) => {
  const showSingleGoalsTable =
    filter.people.length === 1 && !filter.teams.length && filter.reports === '0';
  return !showSingleGoalsTable;
};

export const hasPrimaryFiltersSelected = (filter: GoalsTableFilter) => {
  return !!(filter.people.length || filter.teams.length || filter.reports === '1');
};

export const hasSecondaryFiltersSelected = (filter: GoalsTableFilter) => {
  return !!(filter.status.length || filter.updated.length || filter.search?.length);
};

export const hasFiltersSelected = (filter: GoalsTableFilter) => {
  return hasPrimaryFiltersSelected(filter) || hasSecondaryFiltersSelected(filter);
};

export const hasTooManyGoals = (
  goalsHeaderData: GoalsHeaderData,
  filter?: GoalsTableFilter,
  filteredGoalsCount?: number,
  initialShouldShowMore?: boolean
): boolean => {
  const {filteredGoalsCountMap, directReportLdaps, sTeamLdaps} = goalsHeaderData;
  if (initialShouldShowMore) {
    return false;
  }
  if (filteredGoalsCount) {
    return filteredGoalsCount > MAX_NUM_GOALS_TO_SHOW;
  }
  const filteredCount = getGoalsCount(filteredGoalsCountMap, filter, directReportLdaps, sTeamLdaps);
  return filteredCount > MAX_NUM_GOALS_TO_SHOW;
};

export const getGoalsCount = (
  map: {[ldap: string]: number},
  filter?: GoalsTableFilter,
  directReportLdaps?: string[],
  sTeamLdaps?: string[]
) => {
  if (!filter) {
    return 0;
  }
  let totalGoalsCount = 0;
  filter.people.forEach((ldap) => {
    totalGoalsCount += map[ldap] ?? 0;
  });
  if (filter.reports === '1' && directReportLdaps) {
    directReportLdaps.forEach((ldap) => {
      if (!filter.people.includes(ldap)) {
        totalGoalsCount += map[ldap] ?? 0;
      }
    });
  }
  if (filter.teams.map(({slug}) => slug).includes(STEAM_TEAM_SLUG) && sTeamLdaps) {
    sTeamLdaps.forEach((ldap) => {
      if (!filter.people.includes(ldap)) {
        totalGoalsCount += map[ldap] ?? 0;
      }
    });
  }
  if (filter.teams.length) {
    filter.teams.forEach(({slug}) => {
      if (slug && slug !== STEAM_TEAM_SLUG) {
        totalGoalsCount += map[slug] ?? 0;
      }
    });
  }
  return totalGoalsCount;
};

export const getSelectedQuickFilterFromUrl = (
  userLdap: string,
  managerLdap: string,
  employeeTeams: Team[],
  goalIdFromPath: string | undefined,
  keyResultIdFromPath: string | undefined,
  goalOwnerLdap: string | undefined
): SelectedKey => {
  const urlParams = new URLSearchParams(location.search);
  if (!goalIdFromPath && !keyResultIdFromPath && ![...urlParams.entries()].length) {
    return 'myGoals';
  }
  const filter = getFiltersFromUrl(goalIdFromPath, keyResultIdFromPath, goalOwnerLdap);
  if (isDirectReportsSelected(filter)) {
    return 'directReportsGoals';
  }
  if (isMyGoalsSelected(filter, userLdap)) {
    return 'myGoals';
  }
  if (isManagerGoalsSelected(filter, managerLdap)) {
    return 'managerGoals';
  }
  const employeeTeamSlugs = employeeTeams.map(({slug}) => slug ?? '');
  if (isTeamGoalsSelected(filter, employeeTeamSlugs)) {
    return 'teamGoals';
  }
  if (isCompanyGoalsSelected(filter)) {
    return 'companyGoals';
  }
  return 'others';
};

export const getEmptyPlaceholderTeam = (teamSlug: string) => {
  return {
    team_id: '',
    name: teamSlug === STEAM_TEAM_SLUG ? STEAM_TEAM_NAME : '',
    description: null,
    primary_contact: null,
    primary_contact_ldap: null,
    email: null,
    url: null,
    slack_channel: null,
    dbx_team_id: null,
    slug: teamSlug.toLowerCase(),
  };
};

export const getFiltersFromUrl = (
  goalIdFromPath: string | undefined,
  keyResultIdFromPath: string | undefined,
  goalOwnerLdap: string | undefined
): GoalsTableFilter => {
  const urlParams = new URLSearchParams(location.search);
  if (!goalIdFromPath && !keyResultIdFromPath && ![...urlParams.entries()].length) {
    return getEmptyFilter();
  }
  const reportsFilter = urlParams.get('reports') ?? '0';
  const teamSlugsFilter = urlParams.get('teams')?.split(',') || [];
  const peopleFromUrl = urlParams.get('people')?.toLowerCase()?.split(',') || [];
  const people =
    goalOwnerLdap && !teamSlugsFilter.length && reportsFilter === '0'
      ? new Set([...peopleFromUrl, goalOwnerLdap])
      : new Set(peopleFromUrl);
  const updatedParams = urlParams.get('updated')?.toLowerCase().split(',') || [];
  const formattedUpdated = updatedParams
    .map((updated) => {
      if (updated === 'l45') {
        return 'filter_less_than_45_days';
      }
      if (updated === 'm45') {
        return 'filter_more_than_45_days';
      }
      return '';
    })
    .filter(Boolean);
  return {
    // On page load, populate team filter with only slugs; we will populate rest of team info later
    teams: teamSlugsFilter.map((teamSlug) => getEmptyPlaceholderTeam(teamSlug)),
    status: urlParams.get('status')?.toLowerCase().split(',') || [],
    updated: formattedUpdated,
    search: urlParams.get('q')?.toLowerCase() || null,
    reports: reportsFilter,
    people: Array.from(people),
  };
};

export const getGoalDataFromPath = (
  goalId?: string,
  keyResultId?: string,
  isDraft?: boolean
): DrawerGoalData | undefined => {
  if (!goalId) {
    return undefined;
  }
  return {
    goalId: Number(goalId),
    keyResultId: keyResultId ? Number(keyResultId) : undefined,
    isDraft,
  };
};

export const getCurrentUrl = (location: Location) => {
  return location.search ? `${location.pathname}${location.search}` : location.pathname;
};

export const createUrlFromFilter = (
  userLdap: string,
  filter: GoalsTableFilter,
  goalData: DrawerGoalData | undefined,
  timeframe?: string
): string => {
  const browsePath = goalData ? getBrowsePath(goalData, false) : ROUTE_PATHS.GOALS;
  const urlParams = new URLSearchParams();

  if (filter.reports === '1') {
    urlParams.set('reports', String(1));
  }

  const userNames = filter.people
    .map((ldap) => (ldap.length ? ldap.toLowerCase() : false))
    .filter(Boolean);
  // Only set people filter if it's not only the current user
  const isCurrentUserOnlySelected = userNames.length === 1 && userNames[0] === userLdap;
  if (userNames.length && !isCurrentUserOnlySelected) {
    urlParams.set('people', userNames.join(','));
  }

  const teamSlugs = filter.teams
    .map((team) => (team.slug?.length ? team.slug.toLowerCase() : false))
    .filter(Boolean);
  if (teamSlugs.length) {
    urlParams.set('teams', teamSlugs.join(','));
  }

  const statuses = filter.status.map((status) => status.toLowerCase());
  if (statuses.length) {
    urlParams.set('status', statuses.join(','));
  }

  if (filter.search?.length) {
    urlParams.set('q', filter.search);
  }

  const lastUpdated = filter.updated.map((updated) => updated.toLowerCase());
  if (lastUpdated.length) {
    const formattedUpdated = lastUpdated
      .map((updated) => {
        if (updated === 'filter_less_than_45_days') {
          return 'l45';
        }
        if (updated === 'filter_more_than_45_days') {
          return 'm45';
        }
        return '';
      })
      .filter(Boolean);
    urlParams.set('updated', formattedUpdated.join(','));
  }

  if (timeframe?.length) {
    urlParams.set('t', timeframe);
  }

  const currUrlParams = new URLSearchParams(location.search);
  if (goalData?.goalId && goalData.isDraft && currUrlParams.get('comment') === '1') {
    urlParams.set('comment', String(1));
  } else {
    urlParams.delete('comment');
  }

  const urlParamsString = urlParams.toString();
  return urlParamsString ? `${browsePath}?${urlParamsString}` : browsePath;
};

export const createGoalCopyLink = (goalId: number, keyResultId?: number) => {
  const browsePath = getBrowsePath({goalId, keyResultId});
  return `${location.origin}${browsePath}`;
};

export const getFilterData = (user: PulseUser | undefined, reportingLine: Employee[]) => {
  const userLdap = emailToLdap(user?.email);
  const userName = user?.display_name || '';
  const manager = reportingLine.length >= 2 ? reportingLine[1] : undefined;
  const managerLdap = manager?.ldap || '';
  const managerName = manager?.name || '';
  return {userLdap, userName, managerLdap, managerName};
};

export const getLatestUpdate = (updates: Update[] | undefined = []) => {
  const sortedUpdates = updates.sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );
  const latestUpdate: Update | undefined = sortedUpdates[sortedUpdates.length - 1];
  const previousUpdates: Update[] =
    sortedUpdates.length > 1 ? sortedUpdates.slice(0, sortedUpdates.length - 1) : [];
  return {latestUpdate, previousUpdates};
};

export const getRowData = (
  row: Goal | KeyResult | GoalRead,
  goalOwner: GoalUser,
  pulseUserEmail?: string,
  delegatedBy?: Employee[] | null
) => {
  const isGoalRow = isGoalType(row);
  const currentRowGoalOwner = !isGoalRow ? goalOwner : (row.users?.[0] as GoalUser);
  const canUpdate =
    currentRowGoalOwner?.email === pulseUserEmail ||
    delegatedBy?.some(({email}) => email === currentRowGoalOwner?.email) ||
    (!isGoalRow && row.contributors.some(({ldap}) => ldap === pulseUserEmail?.split('@')[0]));
  const canEdit =
    currentRowGoalOwner?.email === pulseUserEmail ||
    delegatedBy?.some(({email}) => email === currentRowGoalOwner?.email);
  const {latestUpdate, previousUpdates} = getLatestUpdate(row.updates);
  const goalId = isGoalRow ? row.id : row.goal_id;
  const keyResultId = isGoalRow ? undefined : row.id;
  const numKeyResults = isGoalRow ? row.key_results.length : 0;
  const contributorLdaps = !isGoalRow ? row.contributors.map(({ldap}) => ldap) : [];
  return {
    currentRowGoalOwner,
    canEdit,
    canUpdate,
    latestUpdate,
    goalId,
    keyResultId,
    numKeyResults,
    contributorLdaps,
    previousUpdates,
  };
};

export const getBrowsePath = (goalData?: DrawerGoalData, includeUrlParams?: boolean) => {
  const urlPath = ROUTE_PATHS.GOALS;

  // Always remove comment query param when navigating to another browse path
  const currUrlParams = new URLSearchParams(location.search);
  currUrlParams.delete('comment');

  const urlParams =
    includeUrlParams && currUrlParams.size > 0 ? `?${currUrlParams.toString()}` : '';
  if (!goalData) {
    return `${urlPath}${urlParams}`;
  }
  const {goalId, keyResultId} = goalData;
  if (keyResultId) {
    return `${urlPath}/${goalId}/${keyResultId}${urlParams}`;
  }
  return `${urlPath}/${goalId}${urlParams}`;
};

export const shouldShowConfidentialDetails = (goal: GoalData) => {
  return isGoalPrivate(goal);
};

export const isClosedStatus = (status: string | undefined, includeComplete: boolean) => {
  if (!status) {
    return false;
  }
  const closedStatuses: string[] = ['complete', 'cancelled', 'missed'];
  if (!includeComplete) {
    const filteredClosedStatuses = closedStatuses.filter(
      (closedStatus) => closedStatus !== 'complete'
    );
    return filteredClosedStatuses.includes(status);
  }
  return closedStatuses.includes(status);
};

export const hasOpenStatusKeyResult = (
  goal: Goal | GoalRead | undefined,
  goalOwner: GoalUser
): boolean => {
  if (!goal) {
    return false;
  }
  return !!goal.key_results.find((kr) => {
    const {latestUpdate: krLatestUpdate} = getRowData(kr, goalOwner);
    return !isClosedStatus(krLatestUpdate?.status, true);
  });
};

export const getExpandedTableProps = (
  shouldGroupGoalsByEmployee: boolean
): {
  shouldExpandOwnerGoals: boolean;
  shouldExpandContributingKRs: boolean;
  shouldGroupGoalsByEmployee: boolean;
} => {
  if (shouldGroupGoalsByEmployee) {
    return {
      shouldExpandOwnerGoals: false,
      shouldExpandContributingKRs: false,
      shouldGroupGoalsByEmployee,
    };
  }
  return {
    shouldExpandOwnerGoals: false,
    shouldExpandContributingKRs: true,
    shouldGroupGoalsByEmployee,
  };
};

export const isMyGoalsSelected = (filter: GoalsTableFilter, employeeLdap: string) => {
  return (
    !filter.teams.length &&
    filter.reports === '0' &&
    filter.people.length === 1 &&
    filter.people[0].toLowerCase() === employeeLdap.toLowerCase()
  );
};

export const isManagerGoalsSelected = (filter: GoalsTableFilter, managerLdap: string) => {
  return (
    !filter.teams.length &&
    filter.reports === '0' &&
    filter.people.length === 1 &&
    Boolean(managerLdap) &&
    filter.people[0].toLowerCase() === managerLdap.toLowerCase()
  );
};

export const isDirectReportsSelected = (
  filter: GoalsTableFilter,
  quickFilterSelected?: SelectedKey
) => {
  return (
    quickFilterSelected === 'directReportsGoals' ||
    (!filter.people.length && !filter.teams.length && filter.reports === '1')
  );
};

export const isTeamGoalsSelected = (
  filter: GoalsTableFilter,
  employeeTeamSlugs: string[],
  quickFilterSelected?: SelectedKey
) => {
  return (
    quickFilterSelected === 'teamGoals' ||
    (filter.reports === '0' &&
      filter.teams.length === employeeTeamSlugs.length &&
      filter.teams.every((team) => team.slug && employeeTeamSlugs.includes(team.slug)))
  );
};

export const isCompanyGoalsSelected = (
  filter: GoalsTableFilter,
  quickFilterSelected?: SelectedKey
) => {
  return (
    quickFilterSelected === 'companyGoals' ||
    (!filter.people.length &&
      filter.reports === '0' &&
      filter.teams.length === 1 &&
      filter.teams[0].slug?.toLowerCase() === STEAM_TEAM_SLUG)
  );
};

export const getShortenedName = (fullName: string) => {
  const nameTokens = fullName.split(' ');
  if (nameTokens.length === 1) {
    return fullName;
  }
  return `${nameTokens[0]} ${nameTokens[1][0]}.`;
};

export const isFilterSelectedForTeamTable = (filter: GoalsTableFilter) => {
  return (
    filter.teams.length &&
    (filter.status.length > 0 || filter.updated.length > 0 || filter.people.length > 0)
  );
};

export const isOnlyTeamTableSelected = (filter: GoalsTableFilter) => {
  return filter.teams.length === 1 && filter.people.length === 0;
};

export const shouldIncludeSubteamMembers = (teamSlug: string, filter: GoalsTableFilter) => {
  return teamSlug !== DASHBOARDS_DROPBOX_TEAM_SLUG && isOnlyTeamTableSelected(filter);
};

const getInitialExpanded = (
  data: Goal[],
  ldap?: string,
  onlyUnowned?: boolean
): {[id: string]: boolean} => {
  return data.reduce(
    (acc, goal) => {
      return {
        ...acc,
        [`goals-${goal.id}`]: onlyUnowned ? emailToLdap(goal.users?.[0].email) !== ldap : true,
      };
    },
    {} as {[id: string]: boolean}
  );
};

export const getExpandedState = (
  data: Goal[],
  ownerLdap: string | undefined,
  selectedGoalData: DrawerGoalData | undefined,
  expandedParentGoals: {[id: string]: GoalRead} = {},
  shouldExpandOwnerGoals: boolean,
  shouldExpandContributingKRs: boolean,
  shouldGroupGoalsByEmployee: boolean
) => {
  const expandedState = !data
    ? {}
    : shouldGroupGoalsByEmployee
    ? {}
    : shouldExpandContributingKRs && !shouldExpandOwnerGoals
    ? getInitialExpanded(data, ownerLdap, true)
    : shouldExpandOwnerGoals
    ? getInitialExpanded(data)
    : {};
  if (selectedGoalData?.goalId) {
    expandedState[`goals-${selectedGoalData.goalId}`] = true;
  }
  Object.keys(expandedParentGoals).forEach((goalId) => {
    expandedState[`goals-${goalId}`] = true;
    expandedState[`goals-${expandedParentGoals[goalId].id}`] = true;
  });
  return expandedState;
};
