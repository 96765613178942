import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, useTheme, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {DialLockMini, EndToEndWorkflowMini} from '@dropbox/dig-illustrations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {strategiesAtom, StrategyItem} from 'atoms/strategies';
import {Employee} from 'client';
import {Accordion, AccordionIconButton} from 'components/DSYS/Accordion';
import {Avatar} from 'components/DSYS/Avatar';
import {EmptyState} from 'components/DSYS/EmptyState';
import {Layout} from 'components/DSYS/Layout';
import {Lightbox} from 'components/DSYS/Lightbox';
import {OptionalTooltip} from 'components/DSYS/OptionalTooltip';
import {StatusButton} from 'components/DSYS/StatusButton';
import {getStatusOptions, StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {getDefaultColumns, Table} from 'components/DSYS/Table';
import {Title} from 'components/DSYS/Title';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {useColumnResize} from 'components/shared/table/useColumnResize';
import {useSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Editor} from 'pages/Editor';
import {Suspense, useState} from 'react';

import styles from './DSYS.module.css';
import {Note, Path} from './Note';

export const StatusExample = () => {
  const {mode} = useTheme();
  const statusOptions = getStatusOptions(mode);
  const statusButtonTypes = [
    {value: 'no_status'},
    ...statusOptions.flatMap((option) => option.statusOptions),
  ];

  return (
    <Stack gap="16">
      <Title id="status">Status</Title>

      <Note
        type="DSYS/StatusButtonIcon"
        title={`<StatusButtonIcon status="on_track" />
<StatusButtonIcon status="on_track" substatus="not_started" />
<StatusButtonIcon status="on_track" size="small" />
<StatusButtonIcon status="on_track" substatus="not_started" size="small" />`}
        path="src/components/DSYS/StatusButtonIcon.tsx"
        info="StatusButtonIcon is a standalone component that can be used to display status icons."
      >
        <Stack gap="8">
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButtonIcon status={value} />
              </Split.Item>
            ))}
          </Split>
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButtonIcon status={value} substatus="not_started" />
              </Split.Item>
            ))}
          </Split>
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButtonIcon status={value} size="small" />
              </Split.Item>
            ))}
          </Split>
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButtonIcon status={value} substatus="not_started" size="small" />
              </Split.Item>
            ))}
          </Split>
        </Stack>
      </Note>

      <Note
        type="DSYS/StatusButton"
        info="Status buttons can be used to display status with optional substatus, auto update tooltip text, disabled state (which is just a disabled button), and as a label (similar to disabled, but wihout button padding.)"
        path="src/components/DSYS/StatusButton.tsx"
        title={`<StatusButton status="on_track" />
<StatusButton status="on_track" autoUpdateText="Auto update text" />
<StatusButton status="on_track" substatus="no_status" />
<StatusButton status="on_track" isDisabled />
<StatusButton status="on_track" isLabel />`}
      >
        <Stack gap="8">
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButton status={value} />
              </Split.Item>
            ))}
          </Split>
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButton status={value} autoUpdateText="Auto update text" />
              </Split.Item>
            ))}
          </Split>
          <Split gap="8">
            {statusButtonTypes.map(
              ({value}) =>
                value !== 'draft' && (
                  <Split.Item key={value}>
                    <StatusButton status={value} substatus="no_status" />
                  </Split.Item>
                )
            )}
          </Split>
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButton status={value} disabled />
              </Split.Item>
            ))}
          </Split>
          <Split gap="8">
            {statusButtonTypes.map(({value}) => (
              <Split.Item key={value}>
                <StatusButton status={value} isLabel />
              </Split.Item>
            ))}
          </Split>
        </Stack>
      </Note>
    </Stack>
  );
};

export const TableExample = () => {
  const {columnConfigs, dragging, getMouseDownHandler} = useColumnResize(getDefaultColumns());

  return (
    <Stack gap="16">
      <Title id="table" withAccessoryEnd={<Path path="src/dig-overrides/table.module.css" />}>
        Table
      </Title>
      <Note
        type="DSYS/Table"
        info="Can be sorted, resized, filtered, searched, and can be selectable."
        path=" "
      >
        <Stack gap="8">
          <Table
            columnConfigs={columnConfigs}
            dragging={dragging}
            onColumnDrag={getMouseDownHandler}
          />
        </Stack>
      </Note>
    </Stack>
  );
};

export const EditorExample = () => (
  <Stack gap="16">
    <Title id="editor" withAccessoryEnd={<Path path="src/pages/Editor.tsx" />}>
      Editor
    </Title>
    <Note
      type="DSYS/RichTextEditor"
      path="src/components/DSYS/RichTextEditor.tsx"
      info="WYSIWYG editor that uses Lexical under the hood. Supports various features through props such as @mentions, images/video, and custom themes"
    >
      <Box backgroundColor="Background Base" borderRadius="Medium" paddingBottom="24">
        <Suspense>
          <Editor />
        </Suspense>
      </Box>
    </Note>
  </Stack>
);

export const UpdatesExample = () => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  if (!employee?.ldap) {
    return null;
  }

  return (
    <Stack gap="16">
      <Title id="update-post">UpdatePost</Title>
      <Note
        type="DSYS/UpdatePost"
        path="src/components/DSYS/UpdatePost.tsx"
        info="UpdatePost is a helper component that displays a user avatar/name/role, a timestamp, and a status update (if provided.) It's built with underlying components like `UpdatePost.Control`, `UpdatePost.User`, and `UpdatePost.Status`, which you could extend if you need to customize the layout/content"
      >
        <UpdatePost featured ldap={employee.ldap} update={{created_at: new Date().toISOString()}}>
          Featured update without status
        </UpdatePost>

        {['Second update', 'First update'].map((content) => (
          <UpdatePost
            key={content}
            ldap={employee.ldap}
            update={{
              comment: 'string',
              created_at: new Date().toISOString(),
              status: 'on_track',
            }}
          >
            {content}
          </UpdatePost>
        ))}

        <UpdatePost
          featured
          ldap={employee.ldap}
          update={{
            comment: 'string',
            created_at: new Date().toISOString(),
            status: 'on_track',
          }}
        >
          Featured update with status
        </UpdatePost>
      </Note>
    </Stack>
  );
};

export const EmptyStateExample = () => {
  return (
    <Stack gap="16">
      <Title id="empty-state">EmptyState</Title>
      <Note
        type="DSYS/EmptyState"
        path="src/components/DSYS/EmptyState.tsx"
        info="EmptyState is a helper component that displays a title, body, image, and an optional CTA."
        title={`<EmptyState image={} title={} body={} />`}
      >
        <EmptyState
          title="These goals are confidential"
          body="Some goals contain confidential information and cannot be shared at this time"
          image={
            // This is an example of how to change a DIG illustration to a different color
            <Box className={styles.dialLock}>
              <DialLockMini width={64} altText={''} />
            </Box>
          }
        />
      </Note>

      <Note
        type="DSYS/EmptyState(CTA)"
        path="src/components/DSYS/EmptyState.tsx"
        info="EmptyState is a helper component that displays a title, body, image, and an optional CTA."
        title={`<EmptyState image={} title={} body={} cta={to, text} />`}
      >
        <EmptyState
          title="No filters applied"
          body="Apply filters to quickly find the goals that interest you most."
          cta={{
            to: '/dsys',
            text: 'Apply filters',
          }}
          image={<EndToEndWorkflowMini width={64} altText={''} />}
        />
      </Note>
    </Stack>
  );
};

export const AccordionExample = () => {
  const strategies = useAtomValue(strategiesAtom)[2024];

  const renderIconButton = (isActive: boolean, toggle: () => void) => (
    <AccordionIconButton
      isActive={isActive}
      toggle={(e) => {
        e.stopPropagation();
        toggle();
      }}
    />
  );

  const renderStrategy = (
    strategy: StrategyItem,
    expanded: boolean,
    _active: boolean,
    toggle: () => void,
    level = 0
  ) => (
    <>
      <Box
        onClick={level === 0 ? toggle : undefined}
        padding="16"
        cursor="pointer"
        borderRadius="Medium"
        {...withShade({
          state: expanded ? 'animated' : undefined,
        })}
      >
        <Box
          withText={strategy.name}
          withSubtext={strategy.owner}
          withLeftAccessory={strategy.subStrategies && renderIconButton(expanded, toggle)}
          as={LabelGroup}
        />
      </Box>
      {expanded && strategy.subStrategies && (
        <Accordion items={strategy.subStrategies} renderItem={renderStrategy} level={level + 1} />
      )}
    </>
  );

  return (
    <Stack gap="16">
      <Title id="accordion">Accordion</Title>
      <Note
        type="DSYS/Accordion"
        path="src/components/DSYS/Accordion.tsx"
        info="Accordion is a helper component that displays a title, body, and an optional CTA."
        title={`<Accordion title={} body={} cta={to, text} />
<Accordion title={} body={} />`}
      >
        <Accordion items={strategies} renderItem={renderStrategy} tabSize={0} />
      </Note>
    </Stack>
  );
};

export const LightboxExample = () => {
  const [open, setOpen] = useState(false);
  return (
    <Stack gap="16">
      <Title id="lightbox">Lightbox</Title>
      <Note
        type="DSYS/Lightbox"
        path="src/components/DSYS/Lightbox.tsx"
        info="Lightbox is a component that lets you slide through images / video assets."
        title={`<Lightbox title={} body={} cta={to, text} />
<Lightbox title={} body={} />`}
      >
        <Button variant="opacity" onClick={() => setOpen(true)}>
          Open Lightbox
        </Button>
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          assets={[
            'https://picsum.photos/600',
            'https://picsum.photos/605',
            'https://picsum.photos/610',
            'https://picsum.photos/615',
          ]}
          index={0}
        />
      </Note>
    </Stack>
  );
};

const EmployeeSearch = ({
  autoFocus,
  selections,
  placeholder,
  onSelection,
}: {
  autoFocus?: boolean;
  selections: string[];
  placeholder: Employee[];
  onSelection: (employee: Employee) => void;
}) => {
  const [userInputValue, setUserInputValue] = useState('');
  const searchResult = useSearch({input: userInputValue});

  const employeeHints = searchResult.length ? searchResult : placeholder;

  const filteredEmployeeHints = employeeHints.filter(
    (employee) => !selections.find((email) => email === employee.email)
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const handleSelection = (ldap: string) => {
    const selectedEmployee = employeeHints.find((e) => e.ldap === ldap);
    if (selectedEmployee) {
      onSelection(selectedEmployee);
      setUserInputValue('');
    }
  };

  const renderTypeaheadRow = (employee: Employee) => (
    <Typeahead.Row
      key={employee.user_id}
      value={employee.ldap}
      withTitle={<Text isBold>{employee.name}</Text>}
      withSubtitle={employee.role}
      withLeftAccessory={<Avatar user={employee} />}
    />
  );

  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput
            autoFocus={autoFocus}
            size="large"
            placeholder={t('search_dropboxers')}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            value={userInputValue}
            withRightAccessory={<Box as={UIIcon} src={SearchLine} color="Text Subtle" />}
            {...getTriggerProps({onChange: onInputChange})}
          />

          <Typeahead.Container {...getContentProps()}>
            <Typeahead.Results
              maxResults={50}
              results={filteredEmployeeHints}
              renderRow={renderTypeaheadRow}
            />
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};

export const TypeaheadExample = () => {
  const [selections, setSelections] = useState<Employee[]>([]);
  return (
    <Stack gap="16">
      <Title id="typeahead">Typeahead</Title>
      <Note
        type="DSYS/Typeahead"
        info="Typeahead is a component that lets you search and select from a list of options."
        title={`<Typeahead options={} onSelect={} />`}
      >
        <EmployeeSearch
          selections={selections.map(({email}) => email ?? '')}
          onSelection={(employee) => setSelections([...selections, employee])}
          placeholder={[]}
        />
        <Box style={{minHeight: 500}}>
          {selections.map((employee) => (
            <Avatar key={employee.user_id} user={employee} />
          ))}
        </Box>
      </Note>
    </Stack>
  );
};

export const OptionalTooltipExample = () => {
  const [enabled, setEnabled] = useState(false);
  return (
    <Stack gap="16">
      <Title id="optional-tooltip">OptionalTooltip</Title>
      <Note
        type="DSYS/OptionalTooltip"
        path="src/components/DSYS/OptionalTooltip.tsx"
        info="OptionalTooltip wraps around a normal tooltip, but adds an `enabled` prop for it to be conditionally shown."
        title={`<OptionalTooltip title={} body={} />`}
      >
        <OptionalTooltip enabled={enabled} title="Hovered!">
          <Button variant="opacity" onClick={() => setEnabled(!enabled)}>
            Hover me — click {enabled ? 'to hide' : 'to show'}
          </Button>
        </OptionalTooltip>
      </Note>
    </Stack>
  );
};

export const GridLayoutExample = () => {
  return (
    <Stack gap="16">
      <Title id="grid-layout">Grid Layouts</Title>
      <Note
        type="DSYS/Layout.Container"
        path="src/components/DSYS/Layout.tsx"
        info="Layout.Container is a helper component that takes up the full width of the parent container. You can use the `condensed` prop to take up less space."
        title={`<Layout.Container />
<Layout.Container size="large" />
<Layout.Container size="condensed" />`}
      >
        <Stack gap="16">
          <Layout.Container>
            <Box
              borderRadius="Medium"
              backgroundColor="Highlight Red"
              width="100%"
              style={{height: 100}}
            />
          </Layout.Container>
          <Layout.Container size="large">
            <Box
              borderRadius="Medium"
              backgroundColor="Highlight Red - Bolder"
              width="100%"
              style={{height: 100}}
            />
          </Layout.Container>{' '}
          <Layout.Container size="condensed">
            <Box
              borderRadius="Medium"
              backgroundColor="Highlight Red - Bolder"
              width="100%"
              style={{height: 100}}
            />
          </Layout.Container>
        </Stack>
      </Note>

      <Note
        type="DSYS/Layout.Nav"
        path="src/components/DSYS/Layout.tsx"
        info="Layout.Nav and Layout.Body are helper components that takes up the first 2/last 5 columns of the parent container."
        title={`<Layout.Container>
  <Layout.Nav />
  <Layout.Body />
</Layout.Container>`}
      >
        <Layout.Container>
          <Layout.Nav>
            <Box
              borderRadius="Medium"
              backgroundColor="Highlight Red"
              width="100%"
              style={{height: 100}}
            />
          </Layout.Nav>
          <Layout.Body>
            <Box
              borderRadius="Medium"
              backgroundColor="Highlight Red - Bolder"
              width="100%"
              style={{height: 100}}
            />
          </Layout.Body>
        </Layout.Container>
      </Note>
    </Stack>
  );
};
